import { Box, SpeedDial } from "@mui/material";
import { Promotion } from "../../components/promotion";
import { LivePrice } from "../../components/live-price/internal";
import { WhyBuy } from "../../components/why-buy";
import { Banner } from "../../components/banner";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { Loader } from "../../components/loader";
import { GET_APP_SETTING } from "../../gql/app-setting";

export const Home = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_APP_SETTING);
  const [promotions, setPromotions] = useState<any>([]);

  const appSettings = data?.getAppSetting;

  useEffect(() => {
    if (appSettings) {
      const promoArr = [];
      promoArr.push({ type: "Promotion 1", image: appSettings.promotionOne });
      promoArr.push({ type: "Promotion 2", image: appSettings.promotionTwo });
      promoArr.push({ type: "Promotion 3", image: appSettings.promotionThree });
      promoArr.push({ type: "Promotion 4", image: appSettings.promotionFour });
      promoArr.push({ type: "Promotion 5", image: appSettings.promotionFive });
      promoArr.push({ type: "Promotion 6", image: appSettings.promotionSix });
      setPromotions(promoArr);
    }
  }, [appSettings]);

  if (loading) {
    return (
      <Box pt={4}>
        <Loader />
      </Box>
    );
  }
  return (
    <Box pb={4}>
      {appSettings && <Banner banner={appSettings?.banner} />}
      <LivePrice />
      <Promotion promotions={promotions} />
      <WhyBuy />
      {/* <SpeedDial
        ariaLabel="open account"
        sx={{
          display: { xs: "none", sm: "flex" },
          right: "20px",
          bottom: "20px",
          position: "fixed",
        }}
        color="primary"
        icon={<AddIcon />}
        onClick={() => navigate("/open-account")}
      >
      </SpeedDial> */}
    </Box>
  );
};
